import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 31px;

	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 31px;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}
`;

const TextBannerPrevious = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextBannerPrevious;
