import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 30px;
	z-index: 1;

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 30px;
		line-height: 31px;
	}
	&.hidden {
		opacity: 0;
	}
	&.mg-bt-20 {
		margin-bottom: 20px;
	}
`;

const TextBannerDigit = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextBannerDigit;
