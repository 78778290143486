import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import WhiteCircleWithDot from '../../images/common/white.svg';
import TextBanner from '../typography/TextBanner';
import TextBannerDigit from '../typography/TextBannerDigit';
import PageContainer from '../common/PageContainer';
import TextBannerPrevious from '../typography/TextBannerPrevious';
import ButtonRectangle from '../common/ButtonRectangle';
import ConcentricCircles from '../common/ConcentricCircles';
import getUri from '../../utils/getUri';
import getSlug from '../../utils/getSlug';

const StyledHomeSliderWrapper = styled.div`
	display: none;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-end;
	padding-bottom: 100px;
	position: relative;
	width: 100%;
	z-index: 0;
	@media (min-width: 768px) {
		padding-bottom: 0;
		display: flex;
	}
`;

const StyledNotMovingComponentsContainer = styled.div`
	align-items: center;
	display: none;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	@media (min-width: 768px) {
		display: flex;
	}
`;

const StyledMobileNavigationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 100px;
	justify-content: flex-start;
	padding: 0 30px;
	position: relative;
	width: 100%;
	@media ${({ theme }) => theme.device.w_425} {
		padding-left: calc((100% - 60px) * 1 / 12 + 30px);
		padding-right: calc((100% - 60px) * 1 / 12 + 30px);
	}
	@media (min-width: 768px) {
		padding-left: calc((100% - 124px) * 1 / 12 + 62px);
		padding-right: calc((100% - 124px) * 1 / 12 + 62px);
		height: 140px;
		display: none;
	}
	@media (min-width: 1024px) {
		display: none;
	}
`;

const StyledSingleSlideWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	padding-bottom: 160px;
	position: absolute;
	top: 0;
	width: 100%;
	@media (min-height: 600px) {
		padding-bottom: 200px;
	}
	@media (min-height: 700px) {
		padding-bottom: 275px;
	}
	@media (min-width: 425px) {
		padding-bottom: 180px;
		@media (min-height: 650px) {
			padding-bottom: 275px;
		}
	}
	@media (min-width: 768px) {
		display: flex;
		justify-content: flex-end;
		position: absolute;
		padding-bottom: 0;
		@media (min-height: 850px) {
			padding-bottom: 350px;
		}
	}
	@media (min-width: 768px) {
		display: flex;
		justify-content: flex-end;
		position: absolute;
		padding-bottom: 0;
	}
`;

const StyledImageWrapper = styled.div`
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	height: 100%;
	left: 0;
	object-fit: cover;
	overflow: hidden;
	position: absolute;
	top: 0;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
	width: 100%;
	z-index: 0;
	@media (orientation: portrait) {
		background-image: url(${({ bgImgVertical }) => bgImgVertical});
	}
	&.visible {
		transform: translateX(0);
	}
`;

const StyledBottomElementsWrapper = styled.div`
	filter: blur(10px);
	height: 50%;
	opacity: 0;
	position: relative;
	transform: translateY(45px);
	transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out, filter 0.4s ease-in;
	width: 100%;
	z-index: -1;
	&.visible {
		filter: blur(0);
		opacity: 1;
		transform: translateY(0);
		z-index: 5;
	}
	@media (min-width: 768px) {
		display: flex;
	}
`;

const StyledMobileSlideContentWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	filter: blur(10px);
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 75px;
	opacity: 0;
	position: relative;
	transform: translateX(-45px);
	transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out, filter 0.4s ease-in;
	width: 100%;
	z-index: -1;
	&.visible {
		filter: blur(0);
		opacity: 1;
		transform: translateX(0);
		z-index: 5;
	}
	@media (min-width: 768px) {
		display: none;
	}
`;

const StyledButtonsWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	left: 250px;
	z-index: 8;
	@media ${({ theme }) => theme.device.w_768} {
		position: absolute;
		left: 280px;
	}
	@media (min-width: 1920px) {
		left: 291px;
	}
	.line {
		background-color: white;
		height: 1px;
		margin: 0 20px;
		opacity: 0.5;
		position: relative;
		width: 70px;
		@media (min-width: 1920px) {
			width: 100px;
			margin: 0 30px;
		}
	}
	.line-mobile {
		background-color: white;
		height: 1px;
		margin: 0 20px;
		opacity: 0.5;
		position: relative;
		width: 50px;
	}
`;

const StyledCircleContainer = styled.div`
	position: relative;
	width: 260px;
`;

const initialRotation = (initAngle, finalAngle) => keyframes`
  0% {
		 transform: rotate(${initAngle});
  }
  100% {
		 transform: rotate(${finalAngle})
  }
`;

const StyledCircleWrapper = styled.img`
	position: absolute;
	z-index: 1;
	transform: rotate(${({ circleRotationAngle }) => circleRotationAngle}deg);
	transition: transform 0.2s ease-in-out;
	&.animation {
		animation: linear normal 1
			${({ rotationAngleTo0Deg, rotationAngleToDiagonal }) => initialRotation(rotationAngleTo0Deg, rotationAngleToDiagonal)};
		animation-duration: 0.4s;
		animation-delay: 0s;
	}
	//@media (min-width: 1024px) {
	//	width: 200px;
	//	left: calc(50% - (200px / 2));
	//	position: absolute;
	//	top: calc(50% + (-200px / 2));
	//}
	@media (min-width: 768px) {
		width: 250px;
		left: calc(50% - (250px / 2));
		position: absolute;
		top: calc(50% + (-250px / 2));
	}
	@media (min-width: 1920px) {
		width: 291px;
		left: calc(50% - (291px / 2));
		top: calc(50% + (-291px / 2));
	}
	&.mobile {
		left: 0;
		margin-right: 10px;
		position: relative;
		top: 0;
		width: 80px;
		@media (min-width: 360px) {
			width: 100px;
			margin-right: 35px;
		}
		@media (min-width: 768px) {
			width: 140px;
		}
	}
`;

const StyledHalfHeightElementsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	p {
		color: white;
		font-size: 20px;
	}
`;

const StyledHeaderButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	max-width: 100%;
	padding: 0;
	width: 100%;
	a {
		.custom-redactor {
			text-transform: uppercase;
		}
	}
	@media (min-width: 425px) {
		max-width: 390px;
	}
	@media ${({ theme }) => theme.device.w_768} {
		padding: 70px 0 30px;
		justify-content: center;
		@media (orientation: landscape) {
			padding: 45px 0 30px;
			max-width: 340px;
			> div {
				font-size: 34px;
				line-height: 44px;
			}
		}
		//max-width: 65%;
		// max-width: calc((100% - 250px) / 2.05);
	}
	@media (min-width: 1024px) {
		padding-top: 40px;
		justify-content: flex-start;
		max-width: calc((100% - 200px) / 2);
		@media (orientation: landscape) {
			padding-top: 40px;
			> div {
				font-size: 40px;
				line-height: 54px;
			}
		}
		font-size: 56px;
		line-height: 80px;
	}
	@media (min-width: 1440px) {
		max-width: calc((100% - 260px) / 2.05);
		padding: 50px 0;
	}
	@media (min-width: 1920px) {
		max-width: 550px;
		> div {
			font-size: 60px;
			line-height: 80px;
		}
	}
	.home-banner {
		margin-top: 20px;
		@media (min-height: 1000px) {
			margin-top: 50px;
		}
	}
`;

const StyledMobileSlidesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-end;
	padding-bottom: 50px;
	position: relative;
	width: 100%;
	z-index: 0;
	@media (min-height: 700px) {
		padding-bottom: 100px;
	}
	@media (min-width: 768px) {
		display: none;
	}
`;

const StyledConcentricCirclesWrapper = styled.div`
	//overflow-y: visible;
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	overflow-x: hidden;
	overflow-y: visible;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;

	@media (min-width: 1280px) {
		overflow-x: visible;
	}
`;

class HomeSlider extends Component {
	state = {
		currentSlideIndex: 1,
		numberOfSlides: 4,
		circleRotationAngle: 0,
		rotationAngleTo0Deg: 115,
		rotationAngleToDiagonal: 0,
	};

	componentDidMount() {
		const { data: sliderData } = this.props;
		const { rotationAngleTo0Deg } = this.state;
		this.setState({ numberOfSlides: sliderData.length });
		const screenHeight = window.innerHeight;
		const screenWidth = window.innerWidth;
		const angle = Math.atan(screenHeight / screenWidth);
		const pi = Math.PI;
		const angleInDeg = angle * (180 / pi);
		this.setState({ circleRotationAngle: -angleInDeg + 360 + rotationAngleTo0Deg });
		this.setState({ rotationAngleToDiagonal: -angleInDeg + 360 + rotationAngleTo0Deg });
	}

	handleGoBack(angle) {
		this.setRotationAngle(angle);
		this.previousSlide();
	}

	handleGoNext(angle) {
		this.setRotationAngle(angle);
		this.nextSlide();
	}

	setRotationAngle(angle) {
		this.setState({ circleRotationAngle: angle });
	}

	nextSlide() {
		const { currentSlideIndex, numberOfSlides } = this.state;
		if (currentSlideIndex < numberOfSlides) {
			this.setState({ currentSlideIndex: currentSlideIndex + 1 });
		} else {
			this.setState({ currentSlideIndex: 1 });
		}
	}

	previousSlide() {
		const { currentSlideIndex, numberOfSlides } = this.state;
		if (currentSlideIndex > 1) {
			this.setState({ currentSlideIndex: currentSlideIndex - 1 });
		} else if (currentSlideIndex === 1) {
			this.setState({ currentSlideIndex: numberOfSlides });
		}
	}

	render() {
		const { currentSlideIndex, circleRotationAngle, rotationAngleToDiagonal, rotationAngleTo0Deg, numberOfSlides } = this.state;
		const { data: sliderData, globalButtons, isIntroRendered, activeSite } = this.props;
		const buttonTextNext = globalButtons?.sliderDalej || '';
		const buttonTextPrevious = globalButtons?.sliderCofnij || '';

		return (
			<PageContainer className="full-width">
				<StyledConcentricCirclesWrapper>
					<ConcentricCircles />
				</StyledConcentricCirclesWrapper>
				<StyledNotMovingComponentsContainer>
					<StyledHalfHeightElementsWrapper className="inner-wrapper-large banner-home-slider">
						<TextBannerDigit>{`_${currentSlideIndex}`}</TextBannerDigit>
						<StyledCircleContainer>
							<StyledCircleWrapper
								className={isIntroRendered ? 'animation' : ''}
								rotationAngleToDiagonal={`${rotationAngleToDiagonal}deg`}
								rotationAngleTo0Deg={`${rotationAngleTo0Deg}deg`}
								circleRotationAngle={circleRotationAngle}
								src={WhiteCircleWithDot}
							/>
							<StyledButtonsWrapper>
								{/* eslint-disable-next-line react/button-has-type */}
								<div
									className="custom-cursor-animation"
									onClick={this.handleGoBack.bind(this, circleRotationAngle - 360 / numberOfSlides)}
								>
									<TextBannerPrevious className="custom-cursor-animation">{buttonTextPrevious}</TextBannerPrevious>
								</div>
								<div className="line" />
								{/* eslint-disable-next-line react/button-has-type */}
								<div
									className="custom-cursor-animation"
									onClick={this.handleGoNext.bind(this, circleRotationAngle + 360 / numberOfSlides)}
								>
									<TextBannerPrevious className="custom-cursor-animation">{buttonTextNext}</TextBannerPrevious>
								</div>
							</StyledButtonsWrapper>
						</StyledCircleContainer>
						<TextBannerDigit className="hidden">{`_${currentSlideIndex}`}</TextBannerDigit>
					</StyledHalfHeightElementsWrapper>
				</StyledNotMovingComponentsContainer>
				<StyledMobileSlidesWrapper>
					{sliderData.map((item, key) => {
						const { tekst: header } = item;
						const imageHorizontal = item?.zdjeciePoziome?.[0]?.url || '';
						const imageVertical = item?.zdjeciePionowe?.[0]?.url || '';
						const index = `key-${key}`;
						const buttonText = item?.przyciskTekst || '';
						// eslint-disable-next-line no-unused-vars
						const buttonSlug = item?.przyciskLink?.[0]?.slug || '';
						const buttonUri = item?.przyciskLink?.[0]?.uri || '';
						const buttonSlugOrUri = buttonSlug || buttonUri;

						return (
							<StyledSingleSlideWrapper key={index} className={key + 1 === currentSlideIndex ? 'visible' : ''}>
								<StyledImageWrapper
									key={index}
									className={key + 1 === currentSlideIndex ? 'visible' : ''}
									bgImg={imageHorizontal}
									bgImgVertical={imageVertical}
								/>
								<StyledMobileSlideContentWrapper
									className={
										key + 1 === currentSlideIndex
											? 'inner-wrapper-large banner-home-slider visible'
											: 'inner-wrapper-large banner-home-slider'
									}
								>
									<StyledHeaderButtonWrapper>
										<TextBannerDigit className="mg-bt-20">{`_${currentSlideIndex}`}</TextBannerDigit>
										<TextBanner>{header}</TextBanner>
										{/* eslint-disable-next-line react/button-has-type */}
										<ButtonRectangle className="home-banner" to={`/${getSlug(buttonSlugOrUri, activeSite)}`}>
											{buttonText}
										</ButtonRectangle>
									</StyledHeaderButtonWrapper>
								</StyledMobileSlideContentWrapper>
							</StyledSingleSlideWrapper>
						);
					})}
					<StyledMobileNavigationWrapper>
						<StyledCircleWrapper className="mobile" circleRotationAngle={circleRotationAngle} src={WhiteCircleWithDot} />
						<StyledButtonsWrapper>
							{/* eslint-disable-next-line react/button-has-type */}
							<div className="link" onClick={this.handleGoBack.bind(this, circleRotationAngle - 360 / numberOfSlides)}>
								<TextBannerPrevious className="link">{buttonTextPrevious}</TextBannerPrevious>
							</div>
							<div className="line-mobile" />
							{/* eslint-disable-next-line react/button-has-type */}
							<div className="link" onClick={this.handleGoNext.bind(this, circleRotationAngle + 360 / numberOfSlides)}>
								<TextBannerPrevious className="link">{buttonTextNext}</TextBannerPrevious>
							</div>
						</StyledButtonsWrapper>
					</StyledMobileNavigationWrapper>
				</StyledMobileSlidesWrapper>

				<StyledHomeSliderWrapper>
					{sliderData.map((item, key) => {
						const { tekst: header } = item;
						const imageHorizontal = item?.zdjeciePoziome?.[0]?.url || '';
						const imageVertical = item?.zdjeciePionowe?.[0]?.url || '';
						const index = `key-${key}`;
						const buttonText = item?.przyciskTekst || '';
						// eslint-disable-next-line no-unused-vars
						const buttonSlug = item?.przyciskLink?.[0]?.slug || '';
						const buttonUri = item?.przyciskLink?.[0]?.uri || '';
						return (
							<StyledSingleSlideWrapper key={index} className={key + 1 === currentSlideIndex ? 'visible' : ''}>
								<StyledImageWrapper
									key={index}
									className={key + 1 === currentSlideIndex ? 'visible' : ''}
									bgImg={imageHorizontal}
									bgImgVertical={imageVertical}
								/>
								<StyledBottomElementsWrapper
									className={
										key + 1 === currentSlideIndex
											? 'inner-wrapper-large banner-home-slider visible'
											: 'inner-wrapper-large banner-home-slider'
									}
								>
									<StyledHeaderButtonWrapper>
										<TextBanner>{header}</TextBanner>
										{/* eslint-disable-next-line react/button-has-type */}
										<ButtonRectangle
											className="home-banner"
											to={buttonSlug === '' ? `/${getUri(buttonUri, activeSite)}` : buttonSlug}
										>
											{buttonText}
										</ButtonRectangle>
									</StyledHeaderButtonWrapper>
								</StyledBottomElementsWrapper>
							</StyledSingleSlideWrapper>
						);
					})}
					<StyledMobileNavigationWrapper>
						<StyledCircleWrapper className="mobile" circleRotationAngle={circleRotationAngle} src={WhiteCircleWithDot} />
						<StyledButtonsWrapper>
							{/* eslint-disable-next-line react/button-has-type */}
							<div className="link" onClick={this.handleGoBack.bind(this, circleRotationAngle - 360 / numberOfSlides)}>
								<TextBannerPrevious className="link">{buttonTextPrevious}</TextBannerPrevious>
							</div>
							<div className="line-mobile" />
							{/* eslint-disable-next-line react/button-has-type */}
							<div className="link" onClick={this.handleGoNext.bind(this, circleRotationAngle + 360 / numberOfSlides)}>
								<TextBannerPrevious className="link">{buttonTextNext}</TextBannerPrevious>
							</div>
						</StyledButtonsWrapper>
					</StyledMobileNavigationWrapper>
				</StyledHomeSliderWrapper>
			</PageContainer>
		);
	}
}

export default connect(
	(state) => ({
		isIntroRendered: state.app.isIntroRendered,
	}),
	null,
)(HomeSlider);
