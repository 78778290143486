import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import lottie from 'lottie-web';
import { isMobile } from 'react-device-detect';
import CirclesSVG from '../../images/intro/kangur_animation_circles.inline.svg';
import { toggleIsIntroRendered } from '../../state/app';
import ConcentricCircles from '../common/ConcentricCircles';

const StyledIntroWrapper = styled.div`
	align-items: flex-start;
	background-color: ${({ theme }) => theme.colors.black};
	display: none;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 15;

	@media (min-width: 1024px) {
		&.visible-on-desktop {
			display: flex;
		}
	}

	@media (min-width: 1840px) {
		align-items: center;
		justify-content: flex-start;
	}
`;

const hideAnimation = () => keyframes`
  0% {
		 fill: ${({ theme }) => theme.colors.green};
  }
  100% {
		 fill: transparent;
  }
`;

const showAndHideAnimation = (maxOpacity) => keyframes`
	0% { opacity: 0; }
	50% { opacity: ${maxOpacity} }
	100% { opacity: 0;}
`;

const showAnimation = (maxOpacity) => keyframes`
  0% { opacity: 0; }
  100% { opacity: ${maxOpacity} }
`;

const textAnimation = () => keyframes`
  0% { opacity: 0;}
  100% {opacity: 1; }
`;

const StyledTextWrapper = styled.div`
	height: 32px;
	margin-top: calc(50vh + 170px);
	max-width: 550px;
	opacity: 0;

	@media (min-width: 1840px) {
		margin-top: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-135%, calc(-50% - 3px));
	}
	&.text-animation {
		animation: ${({ animationDuration }) => animationDuration} linear ${({ textAnimationDelay }) => textAnimationDelay} normal forwards
			1 ${textAnimation()};
	}
`;

const StyledCircleImgWrapper = styled.div`
	height: 264px;
	opacity: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center;
	z-index: 17;
	&.circles-animation {
		opacity: 1 !important;
		#circle-1 {
			animation: linear normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: ${({ animationStepDelay }) => animationStepDelay};
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-2 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(2 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-3 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAnimation(1)};
			animation-delay: calc(3 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: calc(${({ animationStepDuration }) => animationStepDuration} / 2);
			opacity: 0;
		}

		#circle-4 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(4 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-5 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(5 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-6 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(6 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-7 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(7 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-8 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(8 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-9 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(9 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-10 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(10 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}

		#circle-11 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(11 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-12 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(12 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-13 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(13 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-14 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(14 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-15 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(15 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-16 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(16 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-17 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(17 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-18 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(18 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-19 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(19 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
		#circle-20 {
			animation: cubic-bezier(0.5, 1, 0.89, 1) normal forwards 1 ${showAndHideAnimation(1)};
			animation-delay: calc(20 * ${({ animationStepDelay }) => animationStepDelay});
			animation-duration: ${({ animationStepDuration }) => animationStepDuration};
			opacity: 0;
		}
	}
`;

const StyledLogoWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 365px;
	justify-content: center;
	left: 50%;
	position: absolute;
	top: calc(50% + 50px);
	transform: translate(-50%, -50%);
	transition: transform 1.5s linear;
	width: 440px;

	&.text-animation {
		.ai {
			&:first-of-type {
				path {
					animation: 1s linear normal forwards 1 ${hideAnimation(1)};
				}
			}
		}
	}
`;

const bgOpacity = (maxOpacity, maxScale) => keyframes`
  0% { opacity: 0; transform: scale(0.2);}
  100% {opacity: ${maxOpacity}; transform: scale(${maxScale});}
`;

const StyledConcentricCirclesWrapper = styled.div`
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&.circle-bg-animation {
		opacity: 1;
		div.bg-circle-3 {
			animation: 0.4s normal forwards 1 ${bgOpacity(0.5, 3)} 1s ease-out;
			opacity: 0;
			transform: scale(0);
		}

		div.bg-circle-2 {
			animation: 0.3s normal forwards 1 ${bgOpacity(0.5, 2.21875)} 1s ease-out;
			opacity: 0;
			transform: scale(0);
		}

		.bg-circle-1 {
			animation: 0.2s normal forwards 1 ${bgOpacity(0.5, 1.6)} 1s ease-out;
			opacity: 0;
			transform: scale(0);
		}
	}
`;

class Intro extends Component {
	// IMPORTANT: classes to set in SVG: 1. circles-layer, circle-{id}

	lottieTimer;

	hideIntroTimer;

	// time in ms
	staticLogoTime = 1000;

	textAnimationDelay = 700;

	animationDurationTime = 1000;

	hideIntroAfterTime = 5000;

	circleDiameter = 226;

	// visibleCirclesCounter;
	circleAnimationTranslateX;

	animationCirclesEl;

	componentDidMount() {
		const { introAnimationData } = this.props || '';
		const { dispatch } = this.props;

		if (isMobile) {
			document.getElementsByTagName('html')[0].style.overflowY = 'auto';
			dispatch(toggleIsIntroRendered(true));
		} else {
			document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
			const screenWidth = window.innerWidth;
			this.animationCirclesEl = document.getElementById('animated-circles');
			if (screenWidth >= 1920) {
				this.circleDiameter = 264;
			}
			this.animationCirclesEl.style.height = `${this.circleDiameter}px`;
			this.lottieIntro = lottie.loadAnimation({
				container: document.querySelector('.lottie-intro'),
				renderer: 'svg',
				loop: true,
				autoplay: false,
				path: introAnimationData,
			});

			this.lottieIntro.addEventListener('DOMLoaded', () => this.actionsAfterJsonLoaded());

			this.hideIntroTimer = setTimeout(() => {
				this.hideIntro();
			}, this.hideIntroAfterTime);
		}
	}

	componentWillUnmount() {
		if (!isMobile) {
			clearTimeout(this.hideIntroTimer);
			clearTimeout(this.lottieTimer);
			this.lottieIntro.removeEventListener('DOMLoaded', () => this.actionsAfterJsonLoaded());
		}
	}

	setCircles() {
		// const screenWidth = window.innerWidth;
		// TODO: to hide circles over screen
		// this.visibleCirclesCounter = (screenWidth / 2 / this.circleDiameter) * 4;
		const circlesLayerSVG = document.getElementsByClassName('circles-layer');
		const circlesLayerWidth = circlesLayerSVG?.[0]?.getBoundingClientRect()?.width || '';
		this.circleAnimationTranslateX = circlesLayerWidth / 2 - this.circleDiameter / 2 - (this.circleDiameter / 4) * 2 - 10;
	}

	hideIntro = () => {
		const { dispatch } = this.props;
		document.getElementsByTagName('html')[0].style.overflowY = 'auto';
		dispatch(toggleIsIntroRendered(true));
	};

	actionsAfterJsonLoaded() {
		const animationLogoEl = document.getElementById('animated-logo');
		const animationTextEl = document.getElementById('animated-text');
		const animationCirclesBgEl = document.getElementById('animated-circles-bg');
		animationLogoEl.classList.add('text-animation');
		this.lottieTimer = setTimeout(() => {
			this.setCircles();
			this.animationCirclesEl.style.left = `calc(50% + ${this.circleAnimationTranslateX}px)`;
			this.animationCirclesEl.classList.add('circles-animation');
			animationLogoEl.style.transform = 'translate(50vw, -50%)';
			animationTextEl.classList.add('text-animation');
			animationCirclesBgEl.classList.add('circle-bg-animation');
			this.lottieIntro.setSpeed(2.5);
			this.lottieIntro.play();
		}, this.staticLogoTime);
	}

	render() {
		const { introTextData } = this.props;

		return (
			<StyledIntroWrapper className="visible-on-desktop">
				<>
					{CirclesSVG && (
						<StyledCircleImgWrapper id="animated-circles" animationStepDelay="0.08s" animationStepDuration="0.5s">
							<CirclesSVG height="100%" />
						</StyledCircleImgWrapper>
					)}
					<StyledTextWrapper
						id="animated-text"
						textAnimationDelay={`${this.textAnimationDelay}ms`}
						animationDuration={`${this.animationDurationTime}ms`}
					>
						<img alt="" src={introTextData} width="550" height="32" />
					</StyledTextWrapper>
					<StyledLogoWrapper id="animated-logo">
						<div className="lottie-intro" />
					</StyledLogoWrapper>

					<StyledConcentricCirclesWrapper id="animated-circles-bg">
						<ConcentricCircles />
					</StyledConcentricCirclesWrapper>
				</>
			</StyledIntroWrapper>
		);
	}
}

export default connect(
	(state) => ({
		isIntroRendered: state.app.isIntroRendered,
	}),
	null,
)(Intro);
